body {
    margin: 0;
    min-width: 375px;
}

.page01 {
    background: url("../public/img/bg-section1.gif") no-repeat center/cover;
    width: 100%;
    height: 1024px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.container_emailForm {
    width:605px;    
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 30px;
}
.emailForm_wrap {
    width: 605px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.emailInput_wrap {
    /* rounded-11xl bg-gray w-[585px] h-[60px] justify-center py-0 px-6 box-border text-left  bg-gray-200 */
    border-radius: 30px;
    width: 585px;
    height: 60px;
    justify-content: center;
    padding: 0px 24px 0px 24px;
    box-sizing: border-box;
    text-align: left;
    background-color: rgba(255,255,255,0.2);
}

.inputDiv {
    position: relative;
    width: 80%;
    height: 60px;
    vertical-align: top;
    margin-top: 0;
}

::placeholder {
    color: #FFFFFF;
}

.inputEmail {
    margin-top: 0px;
    height: 50px;
    width: 100%;
    position: absolute;
    background-color: transparent;
    font-weight: 500;
    border-width: 0px;
    font-size: 18px;
    line-height: 27px;
    font-family: 'Poppins';
    color: #FFFFFF;    
}

.buttonEmail_wrap {
    border-radius: 30px;
    background-color: #FFFFFF;
    width: 150px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -130px;
    cursor: pointer;
}

.buttonEmail {
    position: relative;
    line-height: 25px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Poppins';
    cursor: pointer;
    color: #1E78FF;
}

.main_string {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 60%;
    text-align: center;
    color: rgba(255,255,255,1);
}

.top_string1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 48px;
    line-height: 83px;
    margin-top: 120px;
    margin-bottom: 0px;
}

.top_string2 {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 100px;
}

.page01_bottom {
    display: block;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(255,255,255,0.7);
    text-align: center;    
}

.page01_bottom_mobile {
    display: none;
}

.main_string .string1 {
    margin: 0px;    
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 50px;
    line-height: 67.5px;
}

.main_string .string2 {
    margin: 0px;    
    font-family: 'Poppins';    
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
}

.main_string .string3 {
    margin: 10px;    
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 80px;
    line-height: 67.5px;
}

.main_string .string4 {
    margin-top: 20px;
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.main_string .string5 {
    margin: 0px;        
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.page02 {
    background: url("../public/img/bg-section2.png") no-repeat center/cover;
    width: 100%;
    height: 1024px;
    display: flex;
    align-items: center;
}

.container_page02 {
    margin-left: 150px;
    width: 45%;
}

.page02_string1 {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 50px;
    line-height: 65px;
}

.page02_string2 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 29px;
    line-height: 37.5px;
    top: 0px;
}

.page02_img {
    width: 50%;
    text-align: right;
}

.page02_image {
    width: 634px;
    height: 533px;
}

.page03 {
    background: url("../public/img/bg-section3.png") no-repeat center/cover;
    width: 100%;
    height: 840px;
    display: flex;
    align-items: center;    
}

.container_page03 {
    margin-left: 150px;
    width: 80%;
    color: rgba(255,255,255,1);
}

.page03_string1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 63.5px;
    line-height: 82.5px;    
    margin-bottom: 0px;
}

.page03_string2 {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 34px;
    line-height: 47.5px;
    color: #69EF37;
    margin-top: 0px;
}

.page03_string3 {
    display:inline-block;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;    
}

.page03_string3_mobile {
    display: none;
}

.page04 {
    background: url("../public/img/bg-section4.png") no-repeat center/cover;
    width: 100%;
    height: 840px;
    display: flex;
    align-items: center;    
}


.container_page04 {
    margin-left: 10%;
    width: 85%;
    color: rgba(255,255,255,1);
    text-align: right;
}


.page04_string1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 63.5px;
    line-height: 82.5px;    
    margin-bottom: 0px;
}

.page04_string2 {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 34px;
    line-height: 47.5px;
    color: rgba(105,239,55,1);
    margin-top: 0px;
}

.page04_string3 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;    
}

.page05 {
    background: url("../public/img/bg-section5.png") no-repeat center/cover;
    width: 100%;
    height: 840px;
    display: flex;
    align-items: center;  
}

.container_page05 {
    position: relative;
    text-align: center;
    width:100%;
    color: rgba(255,255,255,1);
    font-family: 'Poppins';
}

.page05_string1 {    
    font-weight: 800;
    font-size: 63.5px;
    line-height: 82.5px;
    margin: 0;
}

.page05_string2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 39px;
}

.page06 {
    background-color: rgba(38,125,255,1);
    width: 100%;
    height: 840px;
    padding-top: 15%;    
}

.page06_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.page06_string1 {
    position: relative;
    font-weight: 400;
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 32.4px;
    color: rgba(255,255,255,0.8);
    width: 100%;
    padding-top: 1.25rem;
    text-align: center;
}

.page06_img1 {
    width: 616px;
    height:240px;    
}

.page06_img2 {
    width: 763px;
    height:56px;
}

.object-cover {
    object-fit: cover;
}

.pt-11 {
    padding-top: 2.75rem;
}

.header_wrap {
    position: fixed;
    width: 100%;
    height: 120px;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0,0,0,0.3);
}

.header {
    position: relative;
    width: 100%;
    display: flex;
    top: 40px;
    justify-content: center;
}

.menu_wrap {
    display: flex;
    width: 80%;
}

.logo {
    display: flex;
    flex-direction: row;
    width: 122px;
    height: 36px;
}

.topMenu1 {
    width: 20%;
}

.topMenu2 {
    width: 16%;    
}

.topMenu3 {
    width: 48%;    
}

.topMenuLink {
    width: 60%;
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-self: center;    
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 100px;
}

.btn_join {
    color: #FFFFFF;
    top: 0;
    padding: 0.5rem;
    margin-left: auto;
    border-radius: 50px;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(10px);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:10px;
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins';
}

.bottom_wrap {
    position: relative;
    width: 100%;
    height: 400px;
    text-align: left;
    font-size: 14px;
    font-family: 'Pretendard';
    top:250px;
}

.bottom_menu {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.1px;
    text-align: left;    
    font-family: 'Pretendard';
    position: absolute;
    top: 60px;
    left: 10%;
}

.bottom_link {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;    
}

.bottom_string_wrap {
    position: absolute;
    top: 150px;
    left: 10%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 170px;
}

.bottom_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;    
    gap: 20px;
}

.bottom_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;    
    gap: 20px;
}

.bottom_string1 {
    position: relative;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0px;
    margin-top: 0px;
    color: rgba(61,61,61,1);
}

.bottom_string2 {
    position: relative;
    display: inline-block;
    margin-top: 0px;
    width: 400px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(111,111,111,1);
}

.bottom_string3 {
    position: relative;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0px;
    margin-top: 0px;
    color: rgba(61,61,61,1);
}

.bottom_line {
    display: block;
}

.bottom_left_1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.position_relative {
    position: relative;
}

.m-0 {
    margin:0px;
}

.mb-0 {
    margin-bottom: 0px;
}

.bottom_license {
    position: absolute;
    top: 85%;
    left: 10%;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 16.7px;
    color: rgba(165,165,165,1)
}

.agreePopup_wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: left;
    z-index: 50;
    line-height: 1.5;
    top: 0px;
    left: 0px;
}

.container_agreePopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
    width: 430px;
    height: 340px;
    background-color: rgb(100, 116, 130);
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #FFFFFF;
    font-family: 'Poppins';
    margin: auto;
    margin-top:200px;
}

.agreePopup_background {
    position: absolute;
    height: 0.75rem;
    width: 0.75rem;
    margin-left: 380px;
    cursor: pointer;
}

.agreePopup_string1 {
    position: relative;
    font-weight: 700;
    width: 100%;
    text-align: center;
}

.agreePopup_string2 {
    position: relative;
    font-size: 12px;
    width: 330px;
    margin-top: 3%;
    margin: 0px;    
}

.agreePopup_string3 {
    position: relative;
    font-size: 12px;
    width: 330px;
}

.agreePopup_string4 {
    position: relative;
    font-size: 12px;
    width: 330px;
}

.agreePopup_string5 {
    display: flex;
    width: 100%;
    gap: 1.25rem;
    margin-top: 1.25rem;
    justify-content: center;
}

.agreePopup_Btn1 {
    position: relative;
    border-radius: 1.5rem;
    width: 160px;
    height: 35px;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    line-height: 2.25rem;
    opacity: 1;
    cursor: pointer;
}

.agreePopup_Btn2 {
    position: relative;
    border-radius: 1.5rem;
    width: 160px;
    height: 35px;
    background-color: #45BAFF;
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    line-height: 2.25rem;
    opacity: 1;
    cursor: pointer;
}

.downloadApple {
    display: inline;
    cursor: 'pointer';
}

.downloadGoogle {
    display: inline;
    cursor: 'pointer';
    margin-left: 30px;
}

@media (min-width: 769px) and (max-width: 1300px) {
    .topMenu1 {
        width: 30%;
    }
    
    .topMenu2 {
        width: 26%;
    }
    
    .topMenu3 {
        width: 18%;
    }

    .topMenuLink {
        width: 70%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-self: center;    
        font-family: 'Pretendard';
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 70px;
    }

    .main_string {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 90%;
        text-align: center;
        color: rgba(255,255,255,1);
    }
    
    .top_string1 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 40px;
        line-height: 83px;
        margin-top: 120px;
        margin-bottom: 0px;
    }
    
    .top_string2 {
        font-family: 'Poppins';
        font-weight: 300;
        font-size: 18px;
        line-height: 16px;
        margin-top: 0px;
        margin-bottom: 100px;
    }
    
    .page01_bottom {
        display: block;
        width: 100%;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: rgba(255,255,255,0.7);
        text-align: center;    
    }
    
    .page01_bottom_mobile {
        display: none;
    }
    
    .main_string .string1 {
        margin: 0px;    
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 35px;
        line-height: 67.5px;
    }
    
    .main_string .string2 {
        margin: 0px;    
        font-family: 'Poppins';    
        font-weight: 700;
        font-size: 50px;
        line-height: 80px;
    }
    
    .main_string .string3 {
        margin: 10px;    
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 50px;
        line-height: 20px;
    }
    
    .main_string .string4 {
        margin-top: 20px;
        margin-bottom: 0px;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
    }
    
    .main_string .string5 {
        margin: 0px;        
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    .page02 {
        background: url("../public/img/bg-section2.png") no-repeat center/cover;
        width: 100%;
        height: 1024px;
        display: flex;
        align-items: center;
    }
    
    .container_page02 {
        margin-left: 5%;
        width: 45%;
    }
    
    .page02_string1 {
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 25px;
        line-height: 35px;
    }
    
    .page02_string2 {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        top: 0px;
    }
    
    .page02_img {
        width: 50%;
        text-align: right;
    }
    
    .page02_image {
        width: 348px;
        height: 292px;
    }
    
    .downloadApple {
        display: inline;
        cursor: 'pointer';
        width: 150px;
    }

    .downloadGoogle {
        display: inline;
        cursor: 'pointer';
        width: 150px;
        margin-left: 20px;
    }




    .bottom_wrap {
        position: relative;
        width: 100%;
        height: 350px;
        text-align: left;
        font-size: 14px;
        font-family: 'Pretendard';
        top:160px;        
    }
    
    .bottom_menu {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.1px;
        font-family: 'Pretendard';
        position: absolute;
        top: 150px;
        left: 10%;
    }
    
    .bottom_link {
        display: none;
    }
    
    .bottom_string_wrap {
        position: absolute;
        top: 10px;
        left: 10%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 170px;
    }
    
    .bottom_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;    
        gap: 20px;
    }
    
    .bottom_right {
        position: absolute;
        top: 190px;
    }
    
    .bottom_string1 {
        position: relative;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0px;
        margin-top: 0px;
        color: rgba(61,61,61,1);
    }
    
    .bottom_string2 {
        position: relative;
        display: inline-block;
        margin-top: 0px;
        width: 400px;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(111,111,111,1);
    }
    
    .bottom_string3 {
        position: relative;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0px;
        margin-top: 0px;
        color: rgba(111,111,111,1);
    }

    .bottom_line {
        display: none;
    }
    
    .bottom_left_1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
    }
}

@media all and (max-width: 768px) {
    .header_wrap {
        position: fixed;
        width: 100%;
        height: 60px;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: rgba(0,0,0,0.3);
    }

    .topMenuLink {
        display: none;
    }

    .btn_join {
        display: none;
    }

    .header {
        /* relative w-full flex z-50 top-10 justify-center */
        position: relative;
        width: 100%;
        display: flex;
        top: 10px;
        justify-content: center;
    }

    .logo {
        display: flex;
        flex-direction: row;
        width: 79px;
        height: 23px;
    }

    .main_string {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 80%;
        text-align: center;
        color: rgba(255,255,255,1);
    }
    
    .top_string1 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 20px;
        line-height: 34.5px;
        margin-top: 90px;
        margin-bottom: 0px;
    }
    
    .top_string2 {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-top: 0px;
        margin-bottom: 100px;
        color: rgba(208,208,208,1);
    }
    
    .main_string .string1 {
        margin: 0px;    
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 25px;
        line-height: 42px;
    }
    
    .main_string .string2 {
        margin: 0px;    
        font-family: 'Poppins';    
        font-weight: 700;
        font-size: 30px;
        line-height: 50px;
    }
    
    .main_string .string3 {
        margin: 0px;    
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 38px;
        line-height: 53px;
    }
    
    .main_string .string4 {
        margin-top: 20px;
        margin-bottom: 0px;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 10px;
        line-height: 17px;
    }

    .page01_bottom {
        display: none;
    }

    .page01_bottom_mobile {
        display: block;
        width: 100%;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: rgba(255,255,255,0.7);
        text-align: center;    
    }
        
    .container_emailForm {
        width:327px;    
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-top: 30px;
    }

    .emailForm_wrap {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .emailInput_wrap {
        /* rounded-11xl bg-gray w-[585px] h-[60px] justify-center py-0 px-6 box-border text-left  bg-gray-200 */
        border-radius: 30px;
        width: 585px;
        height: 48px;
        justify-content: center;
        padding: 0px 24px 0px 24px;
        box-sizing: border-box;
        text-align: left;
        background-color: rgba(255,255,255,0.2);
    }

    .inputDiv {
        position: relative;
        width: 80%;
        height: 48px;
        vertical-align: top;
        margin-top: 0;
    }

    ::placeholder {
        color: #FFFFFF;
    }

    .inputEmail {
        margin-top: 0px;
        height: 48px;
        width: 100%;
        position: absolute;
        background-color: transparent;
        font-weight: 500;
        border-width: 0px;
        font-size: 18px;
        line-height: 27px;
        font-family: 'Poppins';
        color: #FFFFFF;    
    }

    .buttonEmail_wrap {
        border-radius: 30px;
        background-color: #FFFFFF;
        width: 150px;
        height: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: -80px;
        cursor: pointer;
    }

    .buttonEmail {
        position: relative;
        line-height: 19.5px;
        font-weight: 600;
        font-size: 14px;
        font-family: 'Poppins';
        cursor: pointer;
        color: #1E78FF;
    }

    .agreePopup_wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: left;
        z-index: 50;
        line-height: 1.5;
        top: 0px;
        left: 0px;
    }
    
    .container_agreePopup {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0.5rem;
        width: 350px;
        height: 340px;
        background-color: rgb(100, 116, 130);
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #FFFFFF;
        font-family: 'Poppins';
        margin: auto;
        margin-top:200px;
    }
    
    .agreePopup_background {
        position: absolute;
        height: 0.75rem;
        width: 0.75rem;
        margin-left: 380px;
        cursor: pointer;
    }
    
    .agreePopup_string1 {
        position: relative;
        font-weight: 700;
        width: 100%;
        text-align: center;
    }
    
    .agreePopup_string2 {
        position: relative;
        font-size: 12px;
        width: 330px;
        margin-top: 3%;
        margin: 0px;    
    }
    
    .agreePopup_string3 {
        position: relative;
        font-size: 12px;
        width: 330px;
    }
    
    .agreePopup_string4 {
        position: relative;
        font-size: 12px;
        width: 330px;
    }
    
    .agreePopup_string5 {
        display: flex;
        width: 100%;
        gap: 1.25rem;
        margin-top: 1.25rem;
        justify-content: center;
    }
    
    .agreePopup_Btn1 {
        position: relative;
        border-radius: 1.5rem;
        width: 160px;
        height: 35px;
        background-color: #FFFFFF;
        color: #000000;
        font-size: 12px;
        text-align: center;
        vertical-align: middle;
        line-height: 2.25rem;
        opacity: 1;
        cursor: pointer;
    }
    
    .agreePopup_Btn2 {
        position: relative;
        border-radius: 1.5rem;
        width: 160px;
        height: 35px;
        background-color: #45BAFF;
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        vertical-align: middle;
        line-height: 2.25rem;
        opacity: 1;
        cursor: pointer;
    }


    .page02 {
        background: url("../public/img/bg-section2.png") no-repeat center/cover;
        width: 100%;
        height: 1024px;
        display: flex;
        justify-content: center;
    }
    
    .container_page02 {        
        position: absolute;
        width: 360px;
        margin-top: 420px;        
        margin-left: 0px;
    }

    .page02_string1 {
        top: 421px;
        font-family: 'Poppins';
        font-weight: 600;
        font-size:28px;
        line-height: 32px;
        margin-bottom: 0px;
    }
    
    .page02_string2 {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
        line-height: 22.4px;
    }

    .page02_img {
        position: absolute;
        width: 100%;        
        text-align: center;
        margin-top: -200px;
    }

    .downloadApple {
        display: none;
    }

    .downloadGoogle {
        display: none;
    }
    
    .page02_image {
        width: 340px;
        height: 318px;
    }
        
    .container_page03 {
        margin-left: 20px;
        width: 90%;
        color: rgba(255,255,255,1);
    }

    .page03_string1 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
        margin-bottom: 0px;
    }

    .page03_string2 {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
        line-height: 22.5px;
        color: #69EF37;
        margin-top: 0px;
    }

    .page03_string3 {
        display: none;
    }

    .page03_string3_mobile {        
        display:inline-block;
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 32px;
        line-height: 49px;    
    }


    .container_page04 {
        margin-left: 10px;
        width: 90%;
        color: rgba(255,255,255,1);
        text-align: left;
    }
    
    
    .page04_string1 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 36px;
        line-height: 46.5px;
        margin-bottom: 0px;
    }
    
    .page04_string2 {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
        line-height: 22.5px;
        color: rgba(105,239,55,1);
        margin-top: 0px;
    }
    
    .page04_string3 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;    
    }


    .page05 {
        background: url("../public/img/bg-section5.png") no-repeat center/cover;
        width: 100%;
        height: 840px;
    }
    
    .container_page05 {
        position: relative;
        text-align: center;
        color: #FFFFFF;
        font-family: 'Poppins';
        margin:0;
    }
    
    .page05_string1 {    
        font-weight: 800;
        font-size: 33.4px;
        line-height: 43.3px;    
    }
    
    .page05_string2 {
        font-weight: 400;
        font-size: 12.5px;
        line-height: 17.5px;    
    }
    
    .page06 {
        background-color: #267DFF;
        width: 100%;
        height: 840px;
    }
    
    .page06_wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25%;
    }
    
    .page06_img1 {
        width: 258px;
        height:100px;
    }

    .page06_img2 {
        width: 319px;
        height: 23px;
    }
    
    .page06_string1 {
        position: relative;
        font-weight: 400;
        font-family: 'Poppins';
        font-size: 12px;
        line-height: 15.5px; 
        color: rgba(255,255,255,0.8);
        width: 100%;
        padding-top: 1.25rem;
        text-align: center;
    }
    
    .bottom_wrap {
        position: relative;
        width: 100%;
        height: 350px;
        text-align: left;
        font-size: 14px;
        font-family: 'Pretendard';
        top:160px;
    }
    
    .bottom_menu {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.1px;
        font-family: 'Pretendard';
        position: absolute;
        top: 150px;
        left: 10%;
    }
    
    .bottom_link {
        display: none;
    }
    
    .bottom_string_wrap {
        position: absolute;
        top: 10px;
        left: 10%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 170px;
    }
    
    .bottom_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;    
        gap: 20px;
    }
    
    .bottom_right {
        position: absolute;
        top: 190px;
    }
    
    .bottom_string1 {
        position: relative;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0px;
        margin-top: 0px;
        color: rgba(61,61,61,1);
    }
    
    .bottom_string2 {
        position: relative;
        display: inline-block;
        margin-top: 0px;
        width: 400px;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(111,111,111,1);
    }
    
    .bottom_string3 {
        position: relative;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0px;
        margin-top: 0px;
        color: rgba(111,111,111,1);
    }

    .bottom_line {
        display: none;
    }
    
    .bottom_left_1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
    }
}







@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@font-face {
  font-family: "Pretendard";
  src: url("../public/font/Pretendard-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Pretendard";
  src: url("../public/font/Pretendard-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "SB AggroOTF";
  src: url("../public/font/SB-aggro-L.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "SB AggroOTF";
  src: url("../public/font/SB-aggro-M.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "SB AggroOTF";
  src: url("../public/font/SB-aggro-B.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Pretendard";
  src: url("../public/font/Pretendard-SemiBold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Pretendard";
  src: url("../public/font/Pretendard-Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Pretendard";
  src: url("../public/font/Pretendard-ExtraBold.otf");
  font-weight: 800;
}